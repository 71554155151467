:root {
  --font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  background-color: black;
  --bg-main-content: #3b3838;
  --title-size: 0.9rem;
  --font-color: white;
  --bg-buttons: #535454;
  --bg-buttons-focus: rgb(85, 85, 85);
  --bg-section-1: rgb(255, 255, 255);
  --bg-section-2: rgb(44, 44, 44);
  --section-height: 93.5vh;
  --header-height: 6.5vh;
}

* {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  overflow: hidden;
  scrollbar-width: none;
}

/* Hiding the scrollbar */
::-webkit-scrollbar {
  display: none;
}

/* Tooltips */
.MuiTooltip-tooltip {
  font-family: var(--font-family);
}

@media (min-width: 1000px) {
  .MuiTooltip-tooltip {
    font-size: 1rem !important;
  }
}

@media (max-width: 999px) {
  .MuiTooltip-tooltip {
    font-size: 0.8rem !important;
  }
}
