.upper-content {
  width: 100vw;
  height: var(--section-height);
  background-color: #161616;
  color: #ffffff;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Estilo para el estado inicial */
.upper-content-text {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 2s ease, transform 2s ease;
}

.upper-content-imgs {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 2s ease, transform 2s ease;
}
/* Estilo para el estado con efecto aplicado */
.upper-content-text.show {
  opacity: 1;
  transform: translateY(0);
}

.upper-content-imgs.show {
  opacity: 1;
  transform: translateY(0);
}

.upper-content-text {
  height: 40%;
  width: 70%;
  margin: 0 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-blend-mode: overlay;
  position: relative;
  background-position: top;
  background-repeat: no-repeat;
}

.main-title {
  font-size: 3.5rem;
  letter-spacing: -0.3rem;
}

.second-title {
  font-size: 1.5rem;
  letter-spacing: -0.1rem;
  margin-top: 1rem;
}

.upper-content-imgs {
  height: 60%;
  width: 80%;
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img-carrusel-container {
  overflow-x: auto;
  white-space: nowrap;
}

.img-carrusel-container img {
  width: 350px;
  height: auto;
}

.arrow-down-container {
  margin-top: 1rem;
  align-items: flex-start;
}

.arrow-down-container lord-icon:hover {
  cursor: pointer;
}

.arrow-down-container lord-icon {
  width: 2.5rem;
  height: 2.5rem;
}

@media (max-width: 999px) {
  .upper-content-text {
    width: 90%;
    margin: 0 5%;
  }

  .main-title {
    font-size: 3rem;
    letter-spacing: -0.25rem;
  }

  .second-title {
    font-size: 1.2rem;
    letter-spacing: -0.12rem;
    margin-top: 0.7rem;
  }

  .arrow-down-container lord-icon {
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 600px) {
  .main-title {
    font-size: 1.75rem;
    letter-spacing: -0.15rem;
  }

  .second-title {
    font-size: 1rem;
    letter-spacing: -0.1rem;
    margin-top: 0.5rem;
  }
}
