.clients-section {
  width: 100vw;
  height: calc(var(--section-height) * 0.5);
  background-color: white;
  color: black;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.clients-section-imgs {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 2s ease, transform 2s ease;
}

.clients-section-imgs.show {
  opacity: 1;
  transform: translateY(0);
}

.main-title {
  font-size: 3.5rem;
  letter-spacing: -0.3rem;
  font-weight: bold;
}

.clients-section-imgs {
  height: 60%;
  width: 85%;
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img-carrusel-container {
  overflow-x: auto;
  white-space: nowrap;
}

.img-carrusel-container img {
  width: 350px;
  height: auto;
}

@media (max-width: 999px) {
  .main-title {
    font-size: 3rem;
    letter-spacing: -0.25rem;
  }
}

@media (max-width: 600px) {
  .main-title {
    font-size: 2.5rem;
    letter-spacing: -0.15rem;
  }

  .img-carrusel-container img {
    width: 240px;
  }
}
