.attributes-section {
  position: relative;
  width: 100vw;
  height: var(--section-height);
  background-color: var(--bg-section-1);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: 1;
}

.attributes-title {
  width: 100%;
  color: black;
  font-size: 3.5rem;
  height: 15%;
  letter-spacing: -0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.attributes-title p {
  padding-right: 0.25rem;
}

.logo-attributes {
  height: 4.5rem;
  margin-left: 1rem;
}

.attributes-grid {
  width: 70%;
  margin: 0 15%;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@keyframes jump {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.01);
  }
}

.card {
  transition: all 0.4s;
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.35);
  font-weight: bold;
  cursor: pointer;
  margin: 0 1.5%;
  padding: 0 1rem;
  width: 20%;
  height: 45%;
  font-size: 1.5rem;
}

.card-primary {
  background: rgb(66, 66, 66);
}

.card-secondary {
  background: rgb(255, 255, 255);
}

.card:hover.card-primary {
  background: rgb(152, 28, 28);
}

.card:hover.card-secondary {
  background: rgb(152, 28, 28);
}

.card-secondary .first-content {
  color: black;
}

.first-content,
.second-content {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.6s;
}

.first-content {
  height: 100%;
  width: 100%;
  opacity: 1;
  letter-spacing: -0.1rem;
}

.card:hover .first-content {
  height: 0px;
  opacity: 0;
}

.card:hover .second-content {
  font-size: 1rem;
  letter-spacing: -0.08rem;
  font-weight: normal;
  line-height: 1.5rem;
  opacity: 1;
  height: 100%;
  transform: rotate(0deg);
}

.second-content {
  height: 0%;
  padding: 2.5%;
  opacity: 0;
  font-size: 0px;
  transform: rotate(90deg) scale(-1);
}

#jumping-card {
  animation: jump 2.5s ease infinite;
}

@media (max-width: 1439px) {
  .attributes-grid {
    height: 85%;
  }

  .card {
    width: 25%;
    height: 47%;
  }
}

@media (max-width: 999px) {
  .attributes-grid {
    height: 80%;
    width: 100%;
  }

  .card {
    width: 27%;
    height: 40%;
    margin: 0 1%;
    padding: 0 0.5rem;
  }
}

@media (max-width: 600px) {
  .attributes-title {
    font-size: 2.5rem;
    margin: 5% 0;
    height: 30%;
    align-items: end;
  }

  .logo-attributes {
    height: 3rem;
    margin-left: 0.5rem;
  }

  .attributes-grid {
    display: none;
    height: 70%;
  }

  .attributes-pagination {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 2%;
  }

  .pagination-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .pagination-buttons lord-icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .left-pagination-button lord-icon {
    transform: rotate(-90deg);
  }

  .right-pagination-button lord-icon {
    transform: rotate(90deg);
  }

  .card {
    width: 55%;
    height: 60%;
    margin: 2.5% 2.5%;
  }
}
