.footer-section {
  height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--bg-section-2);
  padding: 1rem 10%;
  width: 80%;
}

.footer-texts {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.footer-title {
  font-size: 1.5rem;
  letter-spacing: -0.12rem;
  margin-bottom: 1rem;
  width: 100%;
}

.footer-subtitle {
  font-size: 0.9rem;
  letter-spacing: -0.07rem;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.last-section-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22.5%;
  gap: 0.5rem;
}

.final-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.final-logo-text {
  font-size: 2.5rem;
  letter-spacing: -0.2rem;
}

.media-icon {
  color: white;
}

.final-img {
  height: 3.5rem;
}

.footer-form {
  cursor: pointer;
  border-bottom: 1px solid white;
}

@media (max-width: 999px) {
  .footer-section {
    height: var(--section-height);
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0;
    width: 100%;
  }

  .footer-texts {
    margin: 1rem 15%;
    width: 70%;
    height: fit-content;
  }

  .final-img {
    height: 3.5rem;
  }

  .final-logo-text {
    font-size: 2.5rem;
  }

  .last-section-logo {
    width: 100%;
  }

  .social-icons {
    width: 90%;
    justify-content: center;
  }

  .social-icons svg {
    width: 30px;
  }

  .social-icons img {
    width: 30px !important;
  }
}

@media (max-width: 600px) {
  .final-img {
    height: 3rem;
  }

  .final-logo-text {
    font-size: 2rem;
  }
}
