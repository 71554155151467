.services-section {
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  background-color: var(--bg-section-1);
  color: black;
}

.services-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.5rem;
  letter-spacing: -0.3rem;
  height: calc(var(--section-height) * 0.2);
}

.services-title p {
  padding-right: 1rem;
}

.logo-services {
  width: 4.5rem;
}

.initial-text {
  width: 70%;
  font-size: 1.75rem;
  letter-spacing: -0.15rem;
  text-align: center;
  margin-bottom: 3rem;
}

.reasons-list {
  display: flex;
  align-items: start;
  justify-content: space-around;
  flex-wrap: wrap;
  height: calc(var(--section-height) * 0.35);
  width: 80%;
  color: white;
}

.reason {
  text-align: center;
  width: 300px;
  background-color: rgb(102, 102, 102);
  height: fit-content;
  border-radius: 20px;
  padding: 1rem;
  font-size: 2rem;
  letter-spacing: -0.2rem;
}

@media (max-width: 1300px) {
  .reasons-list {
    height: fit-content;
    width: 90%;
  }

  .reason {
    font-size: 1.75rem;
    letter-spacing: -0.15rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 600px) {
  .services-title {
    font-size: 2rem;
    letter-spacing: -0.2rem;
    height: calc(var(--section-height) * 0.1);
  }

  .logo-services {
    width: 3rem;
  }

  .initial-text {
    font-size: 1.25rem;
    letter-spacing: -0.1rem;
    margin-bottom: 1rem;
  }

  .reason {
    font-size: 1.25rem;
    letter-spacing: -0.1rem;
    margin-bottom: 1rem;
  }
}
