/* For enable scrolling must be a limited height*/
.main-page {
  height: 100vh;
  color: white;
}

.app-header {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: var(--header-height);
  margin: 0 15%;
  justify-content: space-between;
  flex-direction: row;
}

.nav-bar-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
}

.nav-bar-items {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
}

.menu-cell-phones {
  display: none !important;
}

.app-body {
  position: fixed;
  top: var(--header-height);
  height: var(--section-height);
  overflow-y: auto;
  scrollbar-width: none;
}

.prodet-icon {
  width: 2.25rem;
  height: 2.25rem;
  margin: 0.5rem 0.25rem;
}

.prodet-icon:hover {
  cursor: pointer;
}

.header-title-1 {
  font-size: 1.75rem;
  letter-spacing: -0.2rem;
}

.header-title-1:hover {
  cursor: pointer;
}

.header-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  text-align: center;
  text-transform: none;
  font-size: 1rem;
  cursor: pointer;
  margin: 0 0.4rem;
  letter-spacing: -0.025rem;
}

.header-button:hover {
  border-bottom: solid;
  border-width: 2.5px;
  border-color: var(--bg-buttons-focus);
}

.prodet-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  text-align: center;
  text-transform: none;
  font-size: 1rem;
  letter-spacing: -0.025rem;
  cursor: pointer;
  margin: 0 0.4rem;
  border: 2px solid var(--bg-buttons-focus);
  border-radius: 30px;
  width: 6rem;
}

.prodet-button:hover {
  background-color: rgb(44, 44, 44);
}

.visible {
  display: 1;
}

.hidden {
  display: none;
}

.close-icon-ham-menu-container {
  height: var(--header-height);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.ham-menu-item {
  text-align: center;
  border-bottom: 2px solid var(--bg-buttons-focus);
  letter-spacing: -0.025rem;
}

.wpp-button {
  position: fixed;
  top: 85vh;
  left: calc(100vw - 150px);
  background-color: #54cc60 !important;
}

.wpp-icon {
  color: white;
}

.tooltip-title {
  white-space: pre-line;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 999px) {
  .header-title-1 {
    flex-grow: 0;
  }

  .header-button {
    display: none;
  }

  .prodet-button {
    display: none;
  }

  .menu-cell-phones {
    display: inline !important;
  }

  .wpp-button {
    top: 70vh;
  }
}

@media (max-width: 600px) {
  .wpp-button {
    width: 3rem !important;
    height: 3rem !important;
    left: calc(100vw - 70px);
    top: 65vh;
  }
}
