.advantages-section {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: var(--section-height);
  background-color: var(--bg-section-2);
  color: #ffffff;
}

.advantages-title {
  display: flex;
  align-items: end;
  justify-content: center;
  text-align: center;
  margin-top: 1rem;
  font-size: 3.5rem;
  letter-spacing: -0.3rem;
  width: 100%;
  height: 10%;
}

.advantages-title p {
  padding-right: 0.5rem;
}

.logo-advantages {
  width: 3.5rem;
}

.indicators {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 25%;
  width: 70%;
}

.single-indicator {
  background-color: rgba(54, 54, 54, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80%;
  width: 17.5%;
  padding: 0.25rem;
  font-size: 1.25rem;
  letter-spacing: -0.11rem;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 3px rgba(118, 118, 118, 0.8);
}

.indicator-value,
.indicator-text {
  width: 100%;
}

.advantages-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 35%;
  width: 100%;
}

.single-advantage {
  background-color: white;
  color: black;
  height: 60%;
  width: 60%;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 3px rgba(118, 118, 118, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.single-advantage-title {
  font-weight: bold;
  font-size: 1.5rem;
  letter-spacing: -0.12rem;
  width: 100%;
}

.single-advantage-content {
  padding: 1.5rem;
  font-size: 1.2rem;
  letter-spacing: -0.11rem;
  width: 90%;
}

.advantages-container lord-icon {
  cursor: pointer;
}

.left-pagination-button lord-icon {
  transform: rotate(-90deg);
  margin-right: 1rem;
}

.right-pagination-button lord-icon {
  transform: rotate(90deg);
  margin-left: 1rem;
}

@keyframes jump {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.01);
  }
}

@media (max-width: 999px) {
  .advantages-title {
    font-size: 2.5rem;
    letter-spacing: -0.2rem;
    height: 10%;
  }

  .logo-advantages {
    width: 3rem;
  }

  .indicators {
    height: 40%;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .single-indicator {
    width: 28%;
    height: 40%;
  }

  .indicator-icon-container lord-icon {
    height: 30px;
  }

  .indicator-value,
  .indicator-text {
    width: 100%;
    font-size: 1rem;
    letter-spacing: -0.09rem;
  }

  .advantages-container {
    height: 40%;
  }

  .single-advantage {
    height: 80%;
    width: 60%;
  }

  .single-advantage-title {
    font-size: 1.2rem;
    letter-spacing: -0.11rem;
  }

  .single-advantage-content {
    font-size: 1rem;
    letter-spacing: -0.09rem;
  }

  .advantages-container lord-icon {
    width: 30px;
  }
}

@media (max-width: 600px) {
  .advantages-title {
    font-size: 2rem;
    letter-spacing: -0.2rem;
  }

  .logo-advantages {
    width: 2.5rem;
  }

  .indicators {
    width: 90%;
  }

  .single-indicator {
    width: 27%;
    height: 40%;
  }

  .indicator-icon-container lord-icon {
    height: 25px;
  }

  .indicator-value,
  .indicator-text {
    width: 100%;
    font-size: 0.9rem;
    letter-spacing: -0.07rem;
  }

  .advantages-container {
    height: 40%;
  }

  .single-advantage {
    height: 90%;
    width: 60%;
  }

  .single-advantage-title {
    font-size: 1rem;
    letter-spacing: -0.09rem;
  }

  .single-advantage-content {
    font-size: 0.9rem;
    letter-spacing: -0.07rem;
  }

  .advantages-container lord-icon {
    width: 25px;
  }
}
