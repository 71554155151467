.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(37, 37, 37, 0.9);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-form {
  color: rgb(34, 34, 34);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: fit-content;
  width: 40vw;
  background-color: rgb(255, 255, 255);
  z-index: 10;
  border: solid rgb(110, 110, 110);
  border-radius: 2rem;
}

.form-title {
  width: 85%;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 1.75rem;
}

.form-subtitle {
  width: 80%;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}

#form-element {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
}

#email-field,
#name-field,
#tel-field,
#plans-list {
  background-color: rgb(44, 44, 44);
  color: white;
  width: 70%;
  padding: 0 0.5rem;
  height: 2rem;
  text-align: center;
  font-weight: 600;
  border-radius: 0.5rem;
  margin-bottom: 0.25rem;
}

#form-text {
  margin-bottom: 0.25rem;
  font-size: 1rem;
}

#text-field {
  background-color: rgb(44, 44, 44);
  padding: 0.5rem;
  color: white;
  border-radius: 0.5rem;
  width: 70%;
  font-size: 0.9rem;
}

#buttons {
  display: flex;
  align-items: center;
}

#submit-button,
#close-button {
  background-color: rgb(20, 100, 219);
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.15rem;
  color: white;
  margin-bottom: 1rem;
  cursor: pointer;
  text-align: center;
  max-width: 50%;
}

#close-button {
  margin-left: 0.5rem;
  background-color: rgb(75, 75, 75);
}

#close-section {
  width: 85%;
  padding: 0.5rem 0;
  text-align: center;
}

@media (max-width: 940px) {
  .email-form {
    width: 70vw;
    left: 15vw;
    top: 2.5vh;
  }
}

@media (max-width: 590px) {
  #email-field,
  #plans-list,
  #name-field,
  #tel-field,
  #text-field {
    width: 90%;
    font-size: 0.9rem;
  }

  #form-text {
    width: 100%;
    font-size: 0.9rem;
  }

  #submit-button,
  #close-button {
    font-size: 1rem;
  }
}
