.pricing-section {
  width: 100vw;
  position: relative;
  height: var(--section-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-section-2);
  z-index: 1;
}

.background-pricing {
  position: absolute;
  height: 140%;
  opacity: 0.5;
  z-index: -1;
  transform: rotate(90deg);
}

.logo-plans {
  width: 4.5rem;
}

.pricing-title {
  height: 15%;
  font-size: 3.5rem;
  letter-spacing: -0.3rem;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
}

.pricing-title p {
  padding-right: 0.5rem;
}

.pricing-description {
  height: 10%;
  width: 70%;
  font-size: 2rem;
  letter-spacing: -0.175rem;
  text-align: center;
}

.pricing-cards {
  height: 40%;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: rgb(24, 24, 136);
  padding: 1rem;
  font-size: 1.5rem;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button:hover {
  border: solid;
  border-width: 2.5px;
  border-color: var(--bg-buttons-focus);
}

.plan-card {
  background-color: rgba(34, 34, 34, 0.8);
  box-shadow: 0px 0px 5px 3px rgba(118, 118, 118, 0.8);
  width: 33%;
  height: 85%;
  border-radius: 20px;
  margin: 0 1.5%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plan-type {
  font-size: 1.5rem;
  letter-spacing: -0.125rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
}

.plan-features {
  font-size: 1.2rem;
  letter-spacing: -0.1rem;
  display: flex;
  flex-direction: column;
}

.plan-features li {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
}

.check-icon {
  margin-right: 1rem;
}

.success {
  color: rgb(37, 167, 37);
}

.clear {
  color: #921c1c;
}

@media (max-width: 999px) {
  .pricing-section {
    justify-content: flex-start;
  }

  .logo-plans {
    width: 4rem;
  }

  .pricing-title {
    height: 15%;
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  .pricing-description {
    height: 10%;
    font-size: 1.5rem;
    letter-spacing: -0.12rem;
    width: 85%;
    text-align: center;
  }

  .select-plan-container {
    height: 7.5%;
    font-size: 1.2rem;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select-plan-container svg {
    color: var(--font-color) !important;
  }

  .select-plan-container fieldset {
    border: none;
  }

  #select-plan {
    color: var(--font-color) !important;
    font-size: 1.2rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }

  .plan-card {
    width: 70%;
  }

  .button {
    height: fit-content;
    font-size: 1.2rem;
  }

  .MuiMenu-paper {
    background-color: rgb(58, 58, 58) !important;
    color: var(--font-color) !important;
    border-radius: 20px !important;
  }

  .MuiMenu-paper li {
    font-family: var(--font-family) !important;
    justify-content: center;
  }

  .Mui-selected {
    background-color: var(--bg-buttons-focus) !important;
    font-size: 1.2rem;
  }

  .MuiMenuItem {
    font-size: 1.2rem;
  }

  .background-pricing {
    height: 120%;
  }
}

@media (max-width: 600px) {
  .logo-plans {
    width: 3rem;
  }

  .pricing-title {
    height: 15%;
    font-size: 2.5rem;
    letter-spacing: -0.2rem;
  }

  .pricing-description {
    height: 10%;
    font-size: 1.2rem;
    letter-spacing: -0.1rem;
  }

  .select-plan-container {
    height: 5%;
    width: 60%;
  }

  #select-plan {
    color: var(--font-color) !important;
    font-size: 1rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  }

  .pricing-cards {
    width: 80%;
  }

  .plan-card {
    width: 100%;
  }

  .button {
    height: fit-content;
    font-size: 1rem;
  }

  .plan-type {
    font-size: 1.2rem;
    letter-spacing: -0.1rem;
  }
  
  .plan-features {
    font-size: 1rem;
    letter-spacing: -0.07rem;
  }

  .background-pricing {
    height: 100%;
  }
}
