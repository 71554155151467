.text-plus-video-container {
  width: 100vw;
  position: relative;
  height: var(--section-height);
  background-color: var(--bg-section-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-titles {
  height: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  margin-bottom: 3rem;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 3.5rem;
  letter-spacing: -0.3rem;
}

.brief {
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  letter-spacing: -0.2rem;
}

.inner-content {
  height: 65%;
  width: 70%;
  margin: 0 15%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  gap: 0.5rem;
}

.text-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 35%;
  gap: 0.5rem;
}

.list-container {
  height: fit-content;
}

.text-line {
  font-size: 1.25rem;
  letter-spacing: -0.08rem;
  margin-bottom: 1rem;
}

.text-line li {
  margin-bottom: 1rem;
  line-height: 1.5rem;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: start;
}

.subscribe-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #00286e;
  padding: 0.25rem;
  border-radius: 30px;
  cursor: pointer;
  color: var(--font-color);
  text-decoration: none;
  width: 60%;
  font-size: 1.5rem;
}

.subscribe-button:hover {
  border: 2px solid white;
}

.video-container {
  width: 65%;
  height: 100%;
  position: relative;
}

.video-container video {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

@media (max-width: 999px) {
  .main-titles {
    height: 30%;
    margin-bottom: 2rem;
  }

  .inner-content {
    height: 70%;
    flex-direction: column;
    align-items: center;
  }

  .title {
    font-size: 2.5rem;
    letter-spacing: -0.2rem;
  }

  .brief {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    letter-spacing: -0.15rem;
  }

  .text-container {
    width: 80%;
    height: 50%;
  }

  .text-line {
    font-size: 1rem;
    margin: 0;
    justify-content: center;
  }

  .text-line li {
    margin-bottom: 0.5rem;
  }

  .subscribe-button {
    width: 50%;
    font-size: 1rem;
  }

  .subscribe-button svg {
    width: 30px;
    height: 30px;
  }

  .video-container {
    width: 100%;
    position: relative;
  }
}

@media (max-width: 600px) {
  .main-titles {
    margin-bottom: 1rem;
  }

  .title {
    font-size: 2rem;
    letter-spacing: -0.15rem;
  }

  .brief {
    font-size: 1.5rem;
    letter-spacing: -0.12rem;
  }

  .text-container {
    width: 95%;
    height: 60%;
  }

  .text-line {
    font-size: 0.95rem;
  }

  .text-line li {
    margin-bottom: 0.35rem;
  }

  .subscribe-button {
    width: 80%;
    font-size: 1rem;
  }

  .subscribe-button svg {
    width: 25px;
    height: 25px;
  }

  .video-container {
    width: 100%;
    position: relative;
  }
}
